import "./video.scss";

function Video() {
  return (
    <section className="tumult-video">
      <h2>Alle TUMULT Videos</h2>
      <div className="tumult-video-youtube">
        <div className="tumult-video-youtube-embed">
          <iframe
            title="TUMULT - Für immer"
            width="680"
            height="505"
            src="https://www.youtube.com/embed/igpkb-yuzOM"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    </section>
  );
}

export default Video;
