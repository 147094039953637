import { Link } from "react-router-dom";
import Socials from "../socials/socials";
import "./footer.scss";

function Footer() {
  return (
    <footer>
      <Socials identifier="footer" />
      <ul className="horizontal-flex">
        <li key="home">
          <Link to="/">Home</Link>
        </li>
        <li key="imprint">
          <Link to="/imprint">Imprint</Link>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
