import "./contact.scss";

function Contact() {
  return (
    <section className="tumult-kontakt">
      <div className="text">
        <h2>Get in touch</h2>
        <div className="tumult-kontakt-text-image">
          <div className="image"></div>
          <div className="tumult-kontakt-text">
            <p>
              Lass ein bisschen TUMULT in dein Leben.
              <br />
              Lass mich wissen wenn ich was für dich tun kann, schreib mir.
            </p>
            <a href="mailto:info@tumult-musik.de" className="email-link">
              schreib mir
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
