import "./music.scss";
import appleMusicLogo from "../../assets/img/logo_applemusic_onlight.svg";
//import deezerLogo from "../../assets/img/logo_deezer_onlight.svg";
import spotifyLogo from "../../assets/img/logo_spotify_onlight.svg";
import youtubeLogo from "../../assets/img/logo_youtube_onlight.svg";
import amazonLogo from "../../assets/img/logo_amazonmusic_onlight.svg";
import cover from "../../assets/img/cover.png";

function Music() {
  const musicLinkList = [
    {
      name: "spotify",
      img: spotifyLogo,
      link: "https://open.spotify.com/album/1X5fU89iT48w30SE5wjgac?si=pQZ0vLneT3uL8HC1F9D-wA",
      action: "Hören",
    },
    {
      name: "youtube",
      img: youtubeLogo,
      link: "https://www.youtube.com/channel/UCvUL_vGMdDPBMq9nuUsap7g",
      action: "Schauen",
    },
    {
      name: "apple",
      img: appleMusicLogo,
      link: "https://music.apple.com/de/album/f%C3%BCr-immer-single/1537614967",
      action: "Hören",
    },
    {
      name: "amazon",
      img: amazonLogo,
      link: "https://music.amazon.de/albums/B08LZY9DP5?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE",
      action: "Hören",
    },
/*     {
      name: "deezer",
      img: deezerLogo,
      link: "http://www.google.de",
      action: "Hören",
    }, */
  ];

  return (
    <section className="tumult-musik" id="musik">
      <h2>Musik</h2>
      <div className="tumult-musik-background"></div>
      <div className="tumult-musik-inner">
        <img src={cover} alt="TUMULT Cover" />
        <div className="tumult-musik-links">
          <div className="tumult-musik-title">
            <h3>FÜR IMMER</h3>
            <p>
              „Für immer“ ist für all die Tagträumer, die mit jugendlichem
              Leichtsinn durch den Tag stolpern, nie genug von dem Tag, der
              Nacht und der Gang bekommen.
            </p>
            <span>Wähle deinen Anbieter</span>
          </div>
          <ul>
            {musicLinkList.map((musicLink) => (
              <li key={musicLink.name}>
                <a target="_blank" rel="noreferrer" href={musicLink.link}>
                  <img src={musicLink.img} alt={musicLink.name} />
                  <span>{musicLink.action}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Music;
