import "./bio.scss";
import React from "react";

function Bio() {
  return (
    <section className="tumult-bio">
      <div className="text">
        <h2>About</h2>
        <p>
          Hinter dem Newcomer TUMULT steht der in Stuttgart lebende Gianluca
          Piras.Im Rock zuhause mischt er als TUMULT seine Indie-Wurzeln mit
          sauberem Pop & schmutzigen Synths, mit Geschichten so nah, würde er
          Tagebuch schreiben, würden sie wohl genau so da drin stehen.
        </p>
        <p>
          In der ersten Single „Für immer“ beschreibt TUMULT die ständige Angst
          nicht auszureichen. Nicht genug zu sein, in einer Welt, in der man
          morgens schon bombardiert wird mit perfekten Menschen und perfekten
          Geschichten. TUMULT nimmt genau diese Angst als Antrieb. Antrieb voran
          zu kommen und genau da Träume entstehen zu lassen, wo vorher keine
          waren. Seine eigenen Geschichten zu schreiben. Vielleicht sind die
          nicht so perfekt, dafür aber ehrlich.
        </p>
      </div>
    </section>
  );
}

export default Bio;
