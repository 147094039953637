import "./socials.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faSpotify,
  faItunes,
  faAmazon,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const profileLinks = [
  {
    name: "instagram",
    icon: faInstagram,
    link: "https://instagram.com/tumult.tumult?igshid=3fh3zxvwrrju",
  },
  {
    name: "apple music",
    icon: faItunes,
    link: "https://music.apple.com/de/album/f%C3%BCr-immer-single/1537614967",
  },
  {
    name: "spotify",
    icon: faSpotify,
    link: "https://open.spotify.com/artist/2gws6MaxDNPcp9XBzJTgZR",
  },
  {
    name: "amazon music",
    icon: faAmazon,
    link: "https://music.amazon.de/albums/B08LZY9DP5?marketplaceId=A1PA6795UKMFR9&musicTerritory=DE",
  },
  {
    name: "youtube",
    icon: faYoutube,
    link: "https://www.youtube.com/channel/UCvUL_vGMdDPBMq9nuUsap7g",
  },
];

function Socials(props) {
  console.log(props.identifier);
  return (
    <ul className="tumult-socials horizontal-flex">
      {profileLinks.map((profileLink) => (
        <li key={props.identifier + "-" + profileLink.name}>
          <a target="_blank" rel="noreferrer" href={profileLink.link}>
            <FontAwesomeIcon icon={profileLink.icon} />
          </a>
        </li>
      ))}
    </ul>
  );
}

export default Socials;
