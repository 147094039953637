import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Contact from "./sections/contact/contact";
import Header from "./sections/header/header";
import Imprint from "./sections/imprint/imprint";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Footer from "./sections/footer/footer";
import Music from "./sections/music/music";
import Bio from "./sections/bio/bio";
import Video from "./sections/video/video";

ReactDOM.render(
  <Router>
    <Route exact path="/">
      <Header />
      <Bio />
      <Music />
      <Video />
      <Contact />
      <Footer />
    </Route>
    <Route path="/imprint">
      <Imprint />
      <Footer />
    </Route>
    <Route path="/music">
      <Music />
      <Footer />
    </Route>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
