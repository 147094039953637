import Socials from "../socials/socials";
import "./header.scss";

function Header() {
  return (
    <header className="tumult-header">
      <div className="background"></div>
      <nav>
        <Socials identifier="header" />
      </nav>
      <div className="tumult-header-inner center-absolute">
        <h1>TUMULT</h1>
        <p>
          ... passiert. Jeden Tag. Jeden Monat. Jedes Jahr. Lass ein bisschen
          TUMULT in dein Leben. Schön, dass Du da bist!
        </p>
      </div>
    </header>
  );
}

export default Header;
