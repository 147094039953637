import "./imprint.scss";

function Imprint() {
  return (
    <section className="tumult-imprint">
      <div className="text">
        <h2>Impressum</h2>
        <h3>Verantwortlich für den Inhalt der Website</h3>
        <p>
          TUMULT
          <br />
          Gianluca Prias
        </p>
        <h3>Nutzungsbedingungen & weitere Hinweise</h3>
        <h4>Nutzungsbedingungen</h4>
        <p>
          Diese Website ist Gegenstand der nachfolgenden Nutzungsbedingungen,
          die im Verhältnis zwischen Nutzer und Diensteanbieter mit dem Aufruf
          dieser Website verbindlich vereinbart sind. Soweit spezielle
          Bedingungen für einzelne Nutzungen dieser Website von den
          nachfolgenden Nutzungsbedingungen abweichen, wird in der Website an
          entsprechender Stelle explizit darauf hingewiesen.
        </p>
        <h4>Marken-/Urheberrechte</h4>
        <p>
          Diese Website beinhaltet Daten und Informationen aller Art, die
          marken- und/oder urheberrechtlich zugunsten des Diensteanbieters oder
          im Einzelfall zugunsten Dritter geschützt sind. Es ist daher nicht
          gestattet, die Website im ganzen oder einzelne Teile davon
          herunterzuladen, zu vervielfältigen und zu verbreiten. Gestattet ist
          die technisch bedingte Vervielfältigung zum Zwecke des Browsing.
        </p>
        <h4>Haftung</h4>
        <p>
          Der Diensteanbieter übernimmt die Haftung für die Inhalte seiner
          Website gemäß den gesetzlichen Bestimmungen. Eine Gewähr für
          Richtigkeit und Vollständigkeit der auf der Website befindlichen
          Informationen wird nicht übernommen. Eine Haftung für Verluste oder
          Schäden irgendwelcher Art, die direkt oder indirekt im Zusammenhang
          mit dem Zugriff, der Benutzung, der Leistung oder der Anfrage auf
          dieser Website oder mit deren Verknüpfungen mit anderen Websites
          zusammenhängt, übernimmt der Diensteanbieter nicht.
        </p>
        <h4>Links</h4>
        <p>
          Die Aktivierung bestimmter Verknüpfungen auf dieser Website kann dazu
          führen, dass diese verlassen wird. Wir haben die Verknüpfungen und den
          Inhalt der verknüpften Seiten nicht überprüft und übernehmen weder für
          die technische Qualität noch für deren Inhalte, insbesondere d darauf
          angebotenen Produkten, Dienstleistungen oder sonstigen Angeboten, eine
          Haftung.
        </p>
        <h4>Bilder</h4>
        <p>
          Bildmaterialen auf dieser Website sind Eigenproduktionen. Die
          Bildrechte liegen beim Website Betreiber.
        </p>
        <h3>Datenschutzerklärung</h3>
        <p>
          Alle Ihre persönlichen Angaben werden von uns nur dann erhoben, wenn
          Sie diese von sich aus, zum Beispiel durch Eingabe in das
          Bestellformular, angeben. Darüber hinaus findet keine Weitergabe Ihrer
          Daten statt. Ihre Einwilligung können Sie jederzeit widerrufen. Der
          Widerruf gilt dann für die Zukunft.
        </p>
        <p>
          Auf der Website des Diensteanbieters sind zudem Verweise (Links) auf
          das externe soziale Netzwerk Facebook/Instagram enthalten, nachfolgend
          als Facebook beschrieben. Dieser Internetauftritt wird ausschließlich
          von der Facebook Inc., 1601 California Ave, Palo Alto, CA 94304, USA
          (Facebook) betrieben. Die Verweise sind im Rahmen unseres
          Internetauftritts durch das Facebook Logo oder den Zusatz „Gefällt
          mir” kenntlich gemacht (es werden keine Facebook-Plugin genutzt). Wird
          diesen Verweisen durch einen Klick gefolgt, stellt Ihr Browser eine
          direkte Verbindung mit den Servern von Facebook her. Sofern Sie
          während des Besuchs der Website des Diensteanbieters den Verweisen
          folgen und über Ihr persönliches Benutzerkonto bei Facebook eingeloggt
          sind, wird die Information, dass Sie uns Website besucht haben, an
          Facebook weitergeleitet. Den Besuch der Website kann Facebook ihrem
          Konto zuordnen. Diese Informationen werden an Facebook übermittelt und
          dort gespeichert. Um dies zu verhindern müssen Sie sich vor dem Klick
          auf den Verweis aus Ihrem Facebook-Account ausloggen.
        </p>
        <p>
          Die den Verweisen von Facebook zugewiesenen Funktionen, insbesondere
          die Übermittlung von Informationen und Nutzerdaten, werden nicht
          bereits durch das Besuchen der Website des Diensteanbieters aktiv,
          sondern erst durch den Klick auf die entsprechenden Verweise. Zweck
          und Umfang der Datenerhebung durch Facebook sowie die dortige weitere
          Verarbeitung und Nutzung Ihrer Daten wie auch Ihre diesbezüglichen
          Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatssphäre
          entnehmen Sie bitte den Datenschutzhinweisen von Facebook (
          <a href="http://de-de.facebook.com/privacy/explanation.php">
            http://de-de.facebook.com/privacy/explanation.php
          </a>
          ).
        </p>
        <p>
          Selbiges gilt für die Dienste des externen Netzwerkes Youtube. (
          <a href="https://www.youtube.com/static?template=privacy_guidelines">
            https://www.youtube.com/static?template=privacy_guidelines
          </a>
          ), Spotify (
          <a href="https://www.spotify.com/de/legal/end-user-agreement/">
            https://www.spotify.com/de/legal/end-user-agreement/
          </a>
          ), Apple Music (
          <a href="https://www.apple.com/legal/internet-services/itunes/de/terms.html">
            https://www.apple.com/legal/internet-services/itunes/de/terms.html
          </a>
          ) und Deezer (
          <a href="https://www.deezer.com/legal/cgu">
            https://www.deezer.com/legal/cgu
          </a>
          ).
        </p>
        <h3>Zugrundeliegendes Recht</h3>
        <p>Es gilt deutsches Recht.</p>
      </div>
    </section>
  );
}

export default Imprint;
